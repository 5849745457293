//
// Engage panel(used for demo product demo)
//

.app-engage {
	position: fixed;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	z-index: 5;
	padding-right: 12px;

	.app-engage-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		box-shadow: var(--#{$prefix}engage-btn-box-shadow);
		border-width: 1px;
		border-style: solid;
		font-size: 12px;
		font-weight: $font-weight-bold;
		margin-bottom: 8px;
		@include border-radius(6px);
		width: 66px;
		height: 70px;

		@include button-custom-variant(
			$color: var(--#{$prefix}engage-btn-color), 
			$icon-color: var(--#{$prefix}engage-btn-icon-color), 
			$border-color: var(--#{$prefix}engage-btn-border-color), 
			$bg-color: var(--#{$prefix}engage-btn-bg), 
			$color-active: null, 
			$icon-color-active: null, 
			$border-color-active: null,
			$bg-color-active: null
		);

		&.hover-dark:hover {
			color: var(--#{$prefix}dark-inverse);
			border-color: var(--#{$prefix}dark);
			background-color: var(--#{$prefix}dark);

			i,
			.svg-icon {
				color: var(--#{$prefix}dark-inverse);
			}
		}

		&.hover-primary:hover {
			color: var(--#{$prefix}primary-inverse);
			border-color: var(--#{$prefix}primary);
			background-color: var(--#{$prefix}primary);

			i,
			.svg-icon {
				color: var(--#{$prefix}primary-inverse);
			}
		}

		&.hover-success:hover {
			color: var(--#{$prefix}success-inverse);
			border-color: var(--#{$prefix}success);
			background-color: var(--#{$prefix}success);

			i,
			.svg-icon {
				color: var(--#{$prefix}success-inverse);
			}
		}

		&.app-engage-btn-toggle-off {
			width: 35px;
			height: 35px;
		}

		&.app-engage-btn-toggle-on {
			width: 35px;
			height: 35px;
			display: none;
		}
	}

	&.app-engage-hide {
		.app-engage-btn {
			visibility: hidden;

			&.app-engage-btn-toggle-off {
				display: none;
			}

			&.app-engage-btn-toggle-on {
				visibility: visible;
				display: flex;
			}
		}		
	}
}

.engage-btn {
	display: flex;
	align-items: center;
	height: 35px !important;

	@include button-custom-variant(
		$color: var(--#{$prefix}engage-btn-color), 
		$icon-color: var(--#{$prefix}engage-btn-color), 
		$border-color: var(--#{$prefix}engage-btn-bg), 
		$bg-color: var(--#{$prefix}engage-btn-bg), 
		$color-active: var(--#{$prefix}engage-btn-color-active), 
		$icon-color-active: var(--#{$prefix}engage-btn-color-active), 
		$border-color-active: var(--#{$prefix}engage-btn-bg),
		$bg-color-active: var(--#{$prefix}engage-btn-bg)
	);
}